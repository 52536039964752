import React, { useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  Polyline,
  useLoadScript,
} from "@react-google-maps/api";
import startLocation from "../assets/icons/startLocationIcon.svg";
import endLocation from "../assets/icons/endLocationIcon.svg";
import subscriberIcon from "../assets/icons/Subscriber.svg";
import { Box, Skeleton, Typography } from "@mui/material";
import alarm from "../assets/icons/tripAlarm.svg";

const nightModeStyles = [
  // General geometry and labels styling
  {
    elementType: "geometry",
    stylers: [{ color: "#212121" }], // Background color
  },
  {
    elementType: "labels.text.fill",
    stylers: [{ color: "#ffffff" }], // Label text color
  },
  {
    elementType: "labels.text.stroke",
    stylers: [{ color: "#000000" }], // Label text outline color
  },
  // Country labels
  {
    featureType: "administrative.country",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9e9e9e" }], // Country name text color
  },
  // City labels
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [{ color: "#bdbdbd" }], // City name text color
  },
  // Roads (make visible and styled)
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ color: "#383838" }], // Road color
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [{ color: "#ffffff" }], // Road label color
  },
  {
    featureType: "road",
    elementType: "labels.text.stroke",
    stylers: [{ color: "#000000" }], // Road label outline color
  },
  // Arterial roads (main roads)
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [{ color: "#525252" }], // Main road color
  },
  // Highways
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{ color: "#7f7f7f" }], // Highway color
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [{ color: "#ffffff" }], // Highway label color
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "geometry",
    stylers: [{ color: "#a6a6a6" }], // Controlled access road color
  },
  // Local roads (small roads)
  {
    featureType: "road.local",
    elementType: "geometry",
    stylers: [{ color: "#4a4a4a" }], // Local road color
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [{ color: "#ffffff" }], // Local road label color
  },
  // Parks
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{ color: "#181818" }], // Park area color
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [{ color: "#616161" }], // Park label text color
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#000000" }], // Water body color
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [{ color: "#3d3d3d" }], // Water label color
  },
];

const MapComponent = ({
  width = "500px",
  height = "100%",
  center = {},
  enablePin = false,
  onLocationSelected,
  markerLocation = {},
  polyline = false,
  zoom,
  polylinePath = [],
  defaultCenter = {},
  startlocation = {},
  endlocation = {},
  eventData = [],
  children,
  customClick,
  nightModeStyle = true,
  ...props
}) => {
  const libraries = React.useMemo(() => ["places"], []);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDiytvXP2dWy_3E-rJX2NWwnnFWZe4aSjU",
    libraries: libraries,
  });
  const mapRef = useRef(null); // Ref to access the map instance
  const [mapCenter, setMapCenter] = useState(center);
  const [activeMarker, setActiveMarker] = useState(null);
  
  useEffect(() => {
    if (polyline && polylinePath.length > 0 && mapRef.current) {
      const bounds = new window.google.maps.LatLngBounds();
      polylinePath.forEach((point) => {
        bounds.extend(new window.google.maps.LatLng(point.lat, point.lng));
      });
      mapRef.current.fitBounds(bounds);
    } else if (mapRef.current) {
      // Reset center and zoom when polylinePath is empty
      mapRef.current.setCenter(defaultCenter);
      mapRef.current.setZoom(zoom);
    }
  }, [polyline, polylinePath]);

  if (!isLoaded)
    return (
      <div>
        <Skeleton variant="rounded" height={250} />
      </div>
    );

  const handleMapClick = (event) => {
    if (!enablePin) return; // Prevent pinning if the prop is false

    // Get lat and lng from the click event
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

   // Update the marker position

    setMapCenter({ lat, lng });

    // Call the callback function passed from the parent to send lat/lng
    if (onLocationSelected) {
      onLocationSelected({ lat, lng });
    }
  };

  // Filter geofences based on fleet and flag

  return (
    <Box
      sx={{
        height: height,
        width: width,
        border: "1px solid #ccc",
        borderRadius: "1rem",
        mt: 1,
        mb: 1,
      }}
    >
      <GoogleMap
        {...props}
        mapContainerStyle={{
          width: "100%",
          height: "100%",
          borderRadius: "1rem",
        }}
        center={mapCenter}
        zoom={zoom ? zoom : 10}
        options={{
          styles: nightModeStyle ? nightModeStyles : null,
          mapTypeControl: false,
          clickableIcons: false,
          zoomControl: true,
          streetViewControl: false,
          fullscreenControl: true,
        }}
        onLoad={(map) => (mapRef.current = map)}
        onClick={enablePin ? handleMapClick : customClick}
      >
        {children ? (
          children
        ) : markerLocation ? (
          <Marker position={markerLocation} icon={subscriberIcon} />
        ) : null}
        {/* {polyline ? (
          <>
            <Marker
              position={startlocation}
              icon={startLocation}
              title="Start Trip"
            />
            <Marker
              position={endlocation}
              icon={endLocation}
              title="End Trip"
            />
            <Polyline
              key={1}
              path={polylinePath}
              options={{
                fillColor: "#fd5151",
                strokeColor: "#fd5151",
                strokeWeight: 5,
              }}
            />
          </>
        ) : null} */}
        {polyline ? (
          <>
            <Marker
              position={startlocation}
              icon={startLocation}
              title="Start Trip"
            />
            <Marker
              position={endlocation}
              icon={endLocation}
              title="End Trip"
            />
            <Polyline
              key={1}
              path={polylinePath}
              options={{
                strokeOpacity: 0, // Make the main line invisible
                icons: [
                  {
                    icon: {
                      path: window.google.maps.SymbolPath.CIRCLE,
                      fillOpacity: 1,
                      fillColor: "#fd5151",
                      strokeOpacity: 1,
                      strokeColor: "#fd5151",
                      scale: 3, // Adjust scale for dot size
                    },
                    offset: "0",
                    repeat: "15px", // Space between dots
                  },
                ],
              }}
            />
          </>
        ) : null}

        {polyline &&
          eventData?.map((event, index) => (
            <Marker
              key={index}
              position={{ lat: Number(event.lat), lng: Number(event.lng) }}
              icon={alarm}
              onMouseOver={() => setActiveMarker(index)}
              onMouseOut={() => setActiveMarker(null)}
            >
              {activeMarker === index && (
                <InfoWindow
                  options={{ headerDisabled: true }}
                  position={{ lat: Number(event.lat), lng: Number(event.lng) }}
                >
                  <div>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "#283246",
                        textAlign: "center",
                      }}
                    >
                      Alarm Triggered
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "#283246",
                      }}
                    >
                      {event?.ts}
                    </Typography>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          ))}
      </GoogleMap>
    </Box>
  );
};

export default MapComponent;
