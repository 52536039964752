import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import {
  Button,
  Typography,
  Box,
  TextField,
  Paper,
  CircularProgress,
  Grid,
  Modal,
  Backdrop,
  LinearProgress,
  InputLabel,
  useMediaQuery,
} from "@mui/material";
import mastercard from "../../assets/icons/mastercard.svg";
import visacard from "../../assets/icons/visa.svg";
import jcbcard from "../../assets/icons/jcb.svg";
import expresscard from "../../assets/icons/aexpress.svg";
import { usePostData } from "../../hooks/useApi";
import { useLocation, useNavigate } from "react-router-dom";
import paymentAnimation from "../../assets/Lottie/payment_animation.json";
import Lottie from "lottie-react";

const stripePromise = loadStripe(
  "pk_test_51PTMBPP2lRiLb8rerMMomjxITWNJjPSfIRDUBrDqvvNHTcIpUB8Bqm9lFP9Ywx9aNKZ4oqhPF732Ci9sOo5QuGyE00QNmTA96f"
);
const typographyStyle = {
  fontsize: "12px",
  fontWeight: 500,
  color: "#535A6A",
  fontFamily: "DM Sans, Arial, sans-serif",
  display: "flex",
};
const cardElementOptions = {
  style: {
    base: {
      fontSize: "16px",
      color: "#535A6A",
      fontFamily: "DM Sans, Arial, sans-serif",
      "::placeholder": { color: "#aab7c4" },
      padding: "10px 14px",
      backgroundColor: "#ffffff",
      borderRadius: "0.75rem",
      boxSizing: "border-box",
      marginTop: "1rem",
      marginBottom: "1rem",
    },
    invalid: { color: "#fa755a" },
  },
};

const AddCardForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [name, setName] = useState("");

  const [clientSecret, setClientSecret] = useState(
    ""
  );
  const [paymentIntentKey, setPaymentIntent] = useState("");
  const postData = usePostData();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const intentId = queryParams.get("intent_id");
  const isTabletOrMobile = useMediaQuery("(max-width: 960px)");

  // If intent_id is missing, navigate to home
  useEffect(() => {
    if (!intentId) {
      navigate("/");
    }
  }, [intentId, navigate]);

  const getSecrateKey = async (search) => {
    try {
      //   setIsLoadingData(true);
      const { data } = await postData.mutateAsync({
        endpoint: `/validate-intent`,
        data: {
          intent_id: intentId,
        },
      });

      if (data) {
        setClientSecret(data.client_secret);
      }
    } catch (error) {
      console.log(error, "error----");
    } finally {
    }
  };

  useEffect(() => {
    getSecrateKey();
  }, []);

  const handleSubmit = async (event) => {
    if (event) event.preventDefault();
    setLoading(true);
    if (!stripe || !elements) {
      setLoading(false);
      return;
    }

    try {
      const cardNumberElement = elements.getElement(CardNumberElement);
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: cardNumberElement,
            billing_details: {
              name: name,
            },
          },
        }
      );

      if (error) {
        setLoading(false);
        throw new Error(error.message);
      }
      if (paymentIntent) {
        if (paymentIntent.status === "succeeded") {
          setError(null);
          setLoading(false);
          navigate("/payment-confirmation");
        } else if (
          paymentIntent.status === "requires_action" ||
          paymentIntent.status === "requires_confirmation"
        ) {
          // Handle additional actions required
        } else {
          setLoading(false);
          throw new Error(`Unexpected status: ${paymentIntent.status}`);
        }
      } else {
        throw new Error("No paymentIntent returned.");
      }
    } catch (error) {
      console.error("Error:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const nextAction = async () => {
    const { error: nextActionError, paymentIntent } =
      await stripe.handleNextAction({
        clientSecret: paymentIntentKey,
      });

    if (nextActionError) {
      setLoading(false);
    } else if (paymentIntent.status === "succeeded") {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (paymentIntentKey) {
      nextAction();
    }
    // eslint-disable-next-line
  }, [paymentIntentKey]);

  return (
    <Paper
      sx={{
        p: 2,
        borderRadius: "0.938rem",
        boxShadow: "none",
        minHeight: "90vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ width: isTabletOrMobile ? "90%" : "80%" }}>
        {/* Header */}
        <Typography
          sx={{
            color: "#283246",
            fontSize: isTabletOrMobile ? 22 : 26,
            fontWeight: 700,
          }}
        >
          Enter your payment details
        </Typography>

        {/* Payment Description */}
        <Box
          sx={{
            textAlign: "start",
            mb: 2,
            mt: 2,
            width: isTabletOrMobile ? "100%" : "70%",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 500,
              color: "#535A6A",
              fontFamily: "DM Sans, Arial, sans-serif",
            }}
          >
            Pay securely using your credit or debit card.
          </Typography>
          <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
            <img src={mastercard} width={55} height={35} alt="Mastercard" />
            <img src={visacard} width={55} height={35} alt="Visa" />
            <img src={jcbcard} width={55} height={35} alt="JCB" />
            <img
              src={expresscard}
              width={55}
              height={35}
              alt="American Express"
            />
          </Box>
        </Box>

        {/* Form */}
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1, width: isTabletOrMobile ? "100%" : "70%" }}
        >
          {/* Cardholder Name */}
          <Box sx={{ mb: 2 }}>
            <Typography
              sx={{ fontSize: "14px", fontWeight: 500, color: "#535A6A" }}
            >
              Cardholder Name
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              placeholder="Name"
              name="name"
              autoComplete="name"
              autoFocus
              value={name}
              onChange={(e) => setName(e.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "12px",
                  height: "45px",
                  "& input": {
                    padding: "0.8rem",
                  },
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#97A1B6",
                  },
              }}
            />
          </Box>

          {/* Card Number */}
          <Box sx={{ mb: 2 }}>
            <Typography
              sx={{ fontSize: "14px", fontWeight: 500, color: "#535A6A" }}
            >
              Card Number
            </Typography>
            <Box
              sx={{
                "& .StripeElement": {
                  ...cardElementOptions.style.base,
                  border: "1px solid #ced4da",
                  borderRadius: "8px",
                  padding: "10px",
                },
                "& .StripeElement--invalid": { borderColor: "#fa755a" },
              }}
            >
              <CardNumberElement options={cardElementOptions} />
            </Box>
          </Box>

          {/* Expiration and CVC */}
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={6}>
              <Typography
                sx={{ fontSize: "14px", fontWeight: 500, color: "#535A6A" }}
              >
                Expiration
              </Typography>
              <Box
                sx={{
                  "& .StripeElement": {
                    ...cardElementOptions.style.base,
                    border: "1px solid #ced4da",
                    borderRadius: "8px",
                    padding: "10px",
                  },
                }}
              >
                <CardExpiryElement options={cardElementOptions} />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{ fontSize: "14px", fontWeight: 500, color: "#535A6A" }}
              >
                CVC
              </Typography>
              <Box
                sx={{
                  "& .StripeElement": {
                    ...cardElementOptions.style.base,
                    border: "1px solid #ced4da",
                    borderRadius: "8px",
                    padding: "10px",
                  },
                }}
              >
                <CardCvcElement options={cardElementOptions} />
              </Box>
            </Grid>
          </Grid>

          {/* Error Message */}
          {error && (
            <Box
              sx={{
                p: 1,
                bgcolor: "#FFECEC",
                color: "#283246",
                borderLeft: "6px solid",
                borderColor: "#F97F7F",
                borderRadius: 1,
                fontSize: "0.875rem",
                fontWeight: 500,
                mt: 2,
              }}
            >
              {error}
            </Box>
          )}

          {/* Submit Button */}
          <Box sx={{ mt: 4, textAlign: "center" }}>
            <Button
              type="submit"
              variant="contained"
              sx={{
                width: "200px",
                borderRadius: "0.75rem",
                height: "45px",
                textTransform: "none",
                background:
                  "linear-gradient(to right, rgba(83, 90, 106, 1) 0%, rgba(40, 50, 70, 1) 100%)",
                "&:hover": { transform: "scale(1.05)" },
              }}
              disabled={loading}
            >
              Pay
            </Button>
          </Box>

          {/* Loading Modal */}
          <Modal
            open={loading}
            aria-labelledby="loading-modal"
            aria-describedby="loading-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
              style: { backgroundColor: "rgba(0, 0, 0, 0.8)" },
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 300,
                bgcolor: "background.paper",
                borderRadius: "10px",
                boxShadow: 24,
                p: 4,
                textAlign: "center",
              }}
            >
              <Lottie
                style={{
                  background: "transparent",
                  width: "150px",
                  height: "150px",
                  margin: "0 auto",
                }}
                animationData={paymentAnimation}
                loop={true}
              />
              <Typography
                id="loading-description"
                sx={{
                  fontFamily: "DM Sans, Arial, sans-serif",
                  fontSize: "1rem",
                  color: "#535A6A",
                  mt: 2,
                }}
              >
                Payment is processing. Please do not refresh the page or click
                the back button.
              </Typography>

              <Box sx={{ width: "100%", mt: 3 }}>
                <LinearProgress
                  sx={{
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#F97E7E",
                    },
                  }}
                />
              </Box>
            </Box>
          </Modal>
        </Box>
      </Box>
    </Paper>
  );
};

const PaymentSubscriber = () => (
  <Elements stripe={stripePromise}>
    <AddCardForm />
  </Elements>
);

export default PaymentSubscriber;
