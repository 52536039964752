import React, { useEffect, useRef, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import minlatlng from "../../assets/icons/minlatlng.svg";
import maxlatlng from "../../assets/icons/maxlatlng.svg";

import payngo from "../../assets/image/payngo.svg";
import mobilityondemand from "../../assets/image/mobilityondemand.svg";
import enhancedsecuritysuite from "../../assets/image/enhancedsecuritysuite.svg";
import datainsightsimage from "../../assets/image/datainsightsimage.svg";

import axonsmicro from "../../assets/image/axonmicro.svg";
import axonsmicropro from "../../assets/image/axonmicropro.svg";
import axonsmicrotwo from "../../assets/image/axonsmicrotwo.svg";
import axonsmicroprotwo from "../../assets/image/axonsmicroprotwo.svg";
import mobility from "../../assets/icons/mobility.svg";
import enhancedSecurity from "../../assets/icons/enhancedsecurity.svg";
import dataInsights from "../../assets/icons/datainsights.svg";
import fleetmanagmnet from "../../assets/image/fleetmanagment.svg";
import deliveryservice from "../../assets/image/deliveryservice.svg";

import {
  setClientSecrateKey,
  setSelectedProducts,
} from "../../slices/paymentSlice";
import { useDispatch, useSelector } from "react-redux";
import { useGetData, usePostData } from "../../hooks/useApi";
import { setProductPrice } from "../../slices/planDetailsSlice";
import { formatCurrency } from "../Order/util";
import { logout } from "../../slices/authSlice";
import { useSnackbar } from "../../hooks/Snackbar";
import { GetTabPermissons } from "../../utils/GetPermissonsDetails";
import { updateDevices } from "../../slices/devicesSlice";
import { updateAsset } from "../../slices/assetSlice";
import TrackOrder from "./TrackOrder";
import { CircularProgress, Dialog, TextField } from "@mui/material";
// import {
//   FullscreenControl,
//   Map,
//   Marker,
//   NavigationControl,
// } from "react-map-gl";
import MapComponent from "../../components/GlMapComponent";
import { Marker } from "@react-google-maps/api";
const menuItemValues = [0, 25, 50, 100, 200, 500];
const selectedDeviceType = [{value:"S",label:"Micro S"},{value:"R",label:"Micro R"}];
const menuItemStyles = {
  width: "100%",
  maxWidth: 400,
  fontSize: "0.813rem",
  color: "#535A6A",
  fontWeight: 500,
};

export let devicePrices = {
  AxonsMicro: {
    0: { actualPrice: 0, discountedPrice: 0, savings: 0 },
    25: { actualPrice: 0, discountedPrice: 0, savings: 0 },
    50: { actualPrice: 0, discountedPrice: 0, savings: 0 },
    100: { actualPrice: 800, discountedPrice: 0, savings: 200 },
    200: { actualPrice: 1600, discountedPrice: 0, savings: 400 },
    500: { actualPrice: 4000, discountedPrice: 0, savings: 1000 },
  },
  AxonsMicroPro: {
    0: { actualPrice: 0, discountedPrice: 0, savings: 0 },
    25: { actualPrice: 0, discountedPrice: 0, savings: 0 },
    50: { actualPrice: 0, discountedPrice: 0, savings: 0 },
    100: { actualPrice: 850, discountedPrice: 0, savings: 200 },
    200: { actualPrice: 1700, discountedPrice: 0, savings: 400 },
    500: { actualPrice: 4250, discountedPrice: 0, savings: 1000 },
  },
};

const services = [
  {
    title: "Pay N Go",
    description:
      "Access flexible mobility and fleet management. Ideal for on-the-move tracking and analytics.",
    imageSrc: payngo,
    detailedDescription:
      "Enhance your mobility experience with our Pay N Go service, integrating efficient ride-sharing capabilities with in-depth analytics on driver behavior and vehicle performance for a seamless micro-mobility solution.",
  },
  {
    title: "Delivery As A Service",
    description:
      "Streamline your fleet and logistics operations. Includes vehicle tracking and comprehensive analytics.",
    imageSrc: deliveryservice,
    detailedDescription:
      "Enhance your delivery efficiency with our Delivery as a Service plan. Benefit from advanced route optimization and automated dispatching while tracking your deliveries in real-time for a streamlined logistic operation.",
  },
  {
    title: "Fleet Management",
    description:
      "Optimize your fleet operations with enhanced management capabilities and real-time insights.",
    imageSrc: fleetmanagmnet,
    detailedDescription:
      "Maximize fleet performance with our Fleet Management plan. This comprehensive approach offers real-time monitoring, predictive maintenance alerts, and detailed usage analytics, ensuring optimal management of your vehicle rentals and fleet operations.",
  },
];

const estimatedCostStyle = {
  fontWeight: 700,
  color: "#283246",
  fontSize: 15,
};

const monthTextClr = {
  color: "#535A6A",
  fontSize: "0.75rem",
  fontWeight: 400,
  fontFamily: "DM Sans, Arial, sans-serif",
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  maxWidth: 400,
  color: theme.palette.text.primary,
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: "1.5rem",
  borderRadius: "12px",
  display: "flex",
  justifyContent: "space-between",
  padding: "0.5rem",
  flexDirection: "column",
  width: "30%",
  height: "400px",
};

const ServiceCard = ({
  imageSrc,
  title,
  description,
  price,
  id,
  details,
  showPaymentButtons,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate("service-detail", {
      state: { imageSrc, title, description, price, id, details },
    });
  };

  return (
    <Card
      onClick={showPaymentButtons ? handleCardClick : undefined}
      sx={{
        boxShadow: "none",
        width: "100%",
        height: 279,
        borderRadius: "20px",
        border: "2px solid #DDDEE1",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        textAlign: "start",
        cursor: "pointer",
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        "&:hover": {
          transform: "scale(1.03)",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
          borderColor: "#FF8385",
          "& .hoverBox": {
            bgcolor: "#FFF9F9", // Change the background color of the box
          },
        },
      }}
    >
      <Box sx={{ p: 1, textAlign: "center" }}>
        <img src={imageSrc} height={"147"} alt={title} />
      </Box>
      <Box
        className="hoverBox" // Add a class to this Box
        sx={{ p: 2, bgcolor: "#F3F3F4" }}
      >
        <Typography
          sx={{
            color: "#535A6A",
            fontSize: "0.938rem",
            fontWeight: 700,
            fontFamily: theme.typography.fontFamily,
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            color: "#535A6A",
            fontSize: "0.625rem",
            fontWeight: 400,
            fontFamily: theme.typography.fontFamily,
          }}
        >
          {description}
        </Typography>
        <Typography
          sx={{
            color: "#283246",
            fontSize: "1.125rem",
            fontWeight: 700,
            fontFamily: theme.typography.fontFamily,
          }}
        >
          {formatCurrency(price)} <span style={monthTextClr}>/ Month</span>
        </Typography>
      </Box>
    </Card>
  );
};

const FeatureCard = ({
  iconSrc,
  title,
  description,
  image,
  descriptionone,
  descriptiontwo,
  descriptivetitle,
}) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate("/dashboard/feature-detail", {
      state: {
        iconSrc,
        title,
        description,
        descriptionone,
        descriptiontwo,
        descriptivetitle,
        image,
      },
    });
  };

  return (
    <StyledPaper
      sx={{
        my: 1,
        mx: "auto",
        p: 2,
        bgcolor: "rgba(249, 148, 148, 0.1)",
        height: 150,
        boxShadow: "none",
        borderRadius: "20px",
        cursor: "pointer",
        "&:hover": {
          transform: "scale(1.03)",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
          border: "1px solid #FF8385",
        },
      }}
      onClick={handleCardClick}
    >
      <Grid container wrap="nowrap" spacing={2}>
        <Grid item>
          <Avatar
            sx={{
              bgcolor: "#F99494",
              borderRadius: "12px",
              p: 3,
            }}
          >
            <img src={iconSrc} alt="" />
          </Avatar>
        </Grid>
        <Grid item xs sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{
              fontSize: "0.938rem",
              fontWeight: 700,
              color: "#535A6A",
            }}
          >
            {title}
          </Typography>
        </Grid>
      </Grid>
      <Grid sx={{ pt: 1 }}>
        <Grid item xs>
          <Typography
            sx={{
              fontSize: "0.625rem",
              fontWeight: 400,
              color: "#535A6A",
            }}
          >
            {description}
          </Typography>
        </Grid>
      </Grid>
    </StyledPaper>
  );
};
const descriptionMicro =
  "The Axons Micro is a versatile IoT device designed to seamlessly connect and manage data from various sensors, enhancing both collection and security capabilities in real-time cloud storage.";
const descriptionPro =
  "The Axons Micro Pro offers advanced connectivity options, tailored to handle complex data operations efficiently. Ideal for environments demanding rigorous data management and increased security.";
export default function Home() {
  const [quantityMicro, setQuantityMicro] = React.useState(100);
  const [quantityPro, setQuantityPro] = React.useState(100);
  const devicePriceData = useSelector((state) => state.plan.productPrice);
  const [axonsMicroPerUnitPrice, setAxonsMicroPerUnitPrice] = React.useState();
  const [axonsMicroType, setAxonsMicroType] = useState("S");
  const [axonsMicroProType, setAxonsMicroProType] = useState("S");
  const [axonsMicroProPerUnitPrice, setAxonsMicroProPerUnitPrice] =
    React.useState();
  const [showPayment, setShowPayment] = useState(false);
  const [paymentBtn, setPaymentBtn] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const postData = usePostData();
  const getData = useGetData();
  const showSnackbar = useSnackbar();
  const { permissions } = useSelector((state) => state.permission);
  const [orderPermissions, setOrderPermissions] = useState();
  const { currentUserType } = useSelector((state) => state.auth);
  const { currentUserRole } = useSelector((state) => state.auth);
  const [minErrorMessage, setMinErrorMessage] = useState("");
  const [maxErrorMessage, setMaxErrorMessage] = useState("");
  const [viewport, setViewport] = useState({
    longitude: -79.384293, // Default longitude
    latitude: 43.653908, // Default latitude
    zoom: 10,
  });
  const [loading, setIsLoading] = useState(false);
  const [minLatLng, setMinLatLng] = useState({ lat: "", lng: "" });
  const [maxLatLng, setMaxLatLng] = useState({ lat: "", lng: "" });
  const [currentField, setCurrentField] = useState(null);
  const [openMapDialog, setOpenMapDialog] = useState(false);
  const mapRef = useRef();
  const MAPBOX_TOKEN =
    "pk.eyJ1IjoieW9nZW5kcmFrb2thdGUiLCJhIjoiY2x4Z2psc2U1MHU0ajJqcXAwOG5tZTQwZCJ9.syrL1hd-nJkYHxFhEdfulA";

  // const handleMapClick = (event) => {
  //   const { lng, lat } = event.lngLat; // Safely destructure lat and lng from lngLat object

  //   if (currentField === "min") {
  //     setMinLatLng({ lat, lng });
  //   } else if (currentField === "max") {
  //     setMaxLatLng({ lat, lng });
  //   }
  //   setOpenMapDialog(false);
  // };

  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    if (currentField === "min") {
      setMinLatLng({ lat, lng });
      setViewport({
        latitude: lat,
        longitude: lng,
        zoom: 10,
      });
    } else if (currentField === "max") {
      setMaxLatLng({ lat, lng });
      setViewport({
        latitude: lat,
        longitude: lng,
        zoom: 10,
      });
    }
    setOpenMapDialog(false);
  };

  const theme = useTheme();

  const getAssetList = async (search) => {
    try {
      const { data } = await getData.mutateAsync({
        endpoint: "/manage/assets",
        data: { search: search },
      });

      if (data) {
        dispatch(updateAsset(data));
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        showSnackbar(
          "Your session has ended because your account was logged in on another device.",
          "error"
        );
        dispatch(logout());
        return;
      }
      dispatch(updateAsset([]));
      // showSnackbar("Failed to load data", "error");
    } finally {
    }
  };

  const getDeviceList = async () => {
    try {
      const { data } = await getData.mutateAsync({
        endpoint: "/manage/devices",
        data: {
          page: 1,
        },
      });

      if (data) {
        dispatch(updateDevices(data));
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        showSnackbar(
          "Your session has ended because your account was logged in on another device.",
          "error"
        );
        dispatch(logout());
        return;
      }
      // dispatch(updateDevices([]));
      // showSnackbar("Failed to load data", "error");
    } finally {
    }
  };

  const postSeedData = async () => {
    setIsLoading(true);
    try {
      const { data } = await postData.mutateAsync({
        endpoint: "/manage/demo-data",
        data: {
          min_lat: minLatLng.lat,
          min_lng: minLatLng.lng,
          max_lat: maxLatLng.lat,
          max_lng: maxLatLng.lng,
        },
      });

      if (data) {
        setMinLatLng({ lat: "", lng: "" });
        setMaxLatLng({ lat: "", lng: "" });
        showSnackbar("Data generation started successfully!", "success");
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        showSnackbar(
          "Your session has ended because your account was logged in on another device.",
          "error"
        );
        dispatch(logout());
        return;
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDeviceList();
    getAssetList();
  }, []);

  useEffect(() => {
    setOrderPermissions(GetTabPermissons("order", permissions));
  }, []);

  // Function to update the discountedPrice in devicePrices
  function updateDevicePrices(prices, deviceData) {
    const updatedPrices = { ...prices };

    deviceData?.products?.forEach((product) => {
      const { name, price } = product;

      const keyName = name.replace(/\s+/g, "");

      if (updatedPrices[keyName]) {
        Object.keys(updatedPrices[keyName]).forEach((key) => {
          const quantity = parseInt(key, 10);
          updatedPrices[keyName][key].discountedPrice = price * quantity;
        });
      }
    });

    return updatedPrices;
  }

  const updatedDevicePrices = updateDevicePrices(devicePrices, devicePriceData);

  const getClientSecrateKey = async () => {
    try {
      const { data } = await postData.mutateAsync({
        endpoint: "/manage/add-card",
        data: "",
      });

      if (data) {
        setShowPayment(true);
        dispatch(setClientSecrateKey(data.client_secret));
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        showSnackbar(
          "Your session has ended because your account was logged in on another device.",
          "error"
        );
        dispatch(logout());
        return;
      }
      setShowPayment(false);
      dispatch(setClientSecrateKey(""));
    }
  };

  const getProducts = async () => {
    try {
      const { data } = await getData.mutateAsync({
        endpoint: "/manage/products",
        data: "",
      });

      if (data) {
        dispatch(setProductPrice(data));
        setPaymentBtn(true);

        if (data?.products) {
          data?.products?.forEach((product) => {
            if (product?.name === "Axons Micro") {
              setAxonsMicroPerUnitPrice(product.price);
            } else if (product?.name === "Axons Micro Pro") {
              setAxonsMicroProPerUnitPrice(product.price);
            }
          });
        }
      }
    } catch (error) {
      setPaymentBtn(false);
      if (error?.response?.status === 401) {
        showSnackbar(
          "Your session has ended because your account was logged in on another device.",
          "error"
        );
        dispatch(logout());
        return;
      }
      dispatch(setProductPrice(""));
    }
  };

  useEffect(() => {
    getClientSecrateKey();
  }, []);
  useEffect(() => {
    if (orderPermissions?.create === true) {
      getProducts();
    } else {
      setPaymentBtn(false);
    }
  }, [orderPermissions]);

  useEffect(() => {
    if (quantityMicro === 0 && quantityPro === 0) {
      setPaymentBtn(false);
    } else {
      setPaymentBtn(true);
    }
  }, [quantityMicro, quantityPro]);

  const handleChangeMicro = (event) => {
    setQuantityMicro(event.target.value);
  };

  const handleChangeDeviceTypeMicro = (event) => {
    setAxonsMicroType(event.target.value);
  };

  const handleChangeDeviceTypeMicroPro = (event) => {
    setAxonsMicroProType(event.target.value);
  };

  const handleChangeMicroPro = (event) => {
    setQuantityPro(event.target.value);
  };

  function calculateCost(quantity, category) {
    return updatedDevicePrices[category][quantity].discountedPrice;
  }

  const estimatedCost = calculateCost(quantityMicro, "AxonsMicro");
  const estimatedCostPro = calculateCost(quantityPro, "AxonsMicroPro");

  const handlePayment = () => {
    let products = [
      {
        name: "Axons Micro",
        price: estimatedCost,
        quantity: quantityMicro,
        description: descriptionMicro,
        image: axonsMicroType,
        type: axonsMicroType,
      },
      {
        name: "Axons Micro Pro",
        price: estimatedCostPro,
        quantity: quantityPro,
        description: descriptionPro,
        image: axonsMicroProType,
        type: axonsMicroProType,
      },
    ];
    // eslint-disable-next-line
    {
      products.map((product, index) => {
        // Find the matching plan
        const matchingPlan = devicePriceData?.products?.find(
          (item) => item.name === product.name
        );

        // If there's a matching plan, add price and id
        if (matchingPlan) {
          product.id = matchingPlan.id;
          product.category = "product";
        }
      });
    }

    // Filter out products with a quantity of 0
    const selectedProducts = products.filter((product) => product.quantity > 0);

    dispatch(setSelectedProducts(selectedProducts));
    if (selectedProducts.length > 0) {
      navigate(`/payment/${selectedProducts[0].id}`);
    }
  };

  const handleSeedData = () => {
    let isValid = true;

    // Reset error messages
    setMinErrorMessage("");
    setMaxErrorMessage("");

    if (!minLatLng.lat || !minLatLng.lng) {
      setMinErrorMessage("Please add min lat/lng");
      isValid = false;
    }

    if (!maxLatLng.lat || !maxLatLng.lng) {
      setMaxErrorMessage("Please add max lat/lng");
      isValid = false;
    }

    if (isValid) {
      postSeedData();
    }
  };


  const assetList = useSelector((state) => state.assets.assetData);
  const deviceData = useSelector((state) => state.devices?.data);
  const showOrders =
    deviceData?.total > 0 || assetList?.total > 0
      ? false
      : currentUserRole === "Super Admin" || currentUserType === "SERVICE_STATION"
      ? false
      : true;

  // ... existing code ...

  const renderMap = () => {
    return (
      <Box>
        <MapComponent
          customClick={handleMapClick}
          width="100%"
          nightModeStyle={false}
          height="80vh"
          center={{
            lat: viewport.latitude,
            lng: viewport.longitude,
          }}
          zoom={viewport.zoom}
        >
          {minLatLng.lat && minLatLng.lng && (
            <Marker
              position={{ lat: minLatLng.lat, lng: minLatLng.lng }}
              icon={{
                url: minlatlng,
                scaledSize: new window.google.maps.Size(32, 32),
              }}
            />
          )}
          {maxLatLng.lat && maxLatLng.lng && (
            <Marker
              position={{ lat: maxLatLng.lat, lng: maxLatLng.lng }}
              icon={{
                url: maxlatlng,
                scaledSize: new window.google.maps.Size(32, 32),
              }}
            />
          )}
        </MapComponent>
      </Box>
    );
  };
  return (
    <Paper
      sx={{ p: 2, boxShadow: "none", minHeight: "95vh", borderRadius: "10px" }}
    >
      {showOrders ? (
        <TrackOrder />
      ) : (
        <>
          {currentUserRole !== "Super Admin" && (
            <Box
              sx={{
                bgcolor: "#fff",
                mt: 2,
                mb: 2,
                p: 2,
                borderRadius: "0.625rem",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="h4"
                  sx={{
                    mb: 3,
                    fontFamily: theme.typography.fontFamily,
                    fontWeight: 700,
                    fontSize: "1.375rem",
                    color: "#283246",
                  }}
                >
                  Choose Your Perfect Plan
                </Typography>
              </Box>

              <Grid container spacing={2} justifyContent="space-between">
                {services.map((service, index) => {
                  // Find the matching plan
                  const matchingPlan = devicePriceData?.plans?.find(
                    (plan) => plan.name === service.title
                  );

                  // If there's a matching plan, add price and id
                  if (matchingPlan) {
                    service.price = `${matchingPlan.price.toFixed(2)}`;
                    service.id = matchingPlan.id;
                  }

                  return (
                    <Grid item xs={12} md={4} key={index}>
                      <ServiceCard
                        imageSrc={service.imageSrc}
                        title={service.title}
                        description={service.description}
                        price={service.price}
                        id={service.id}
                        details={service.detailedDescription}
                        showPaymentButtons={showPayment}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          )}
        </>
      )}
      {currentUserRole !== "Super Admin" && (
        <>
          <Box
            sx={{
              bgcolor: "#fff",
              borderRadius: "0.625rem",
              mt: 2,
              mb: 2,
              p: 1,
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  color: "#283246",
                  fontSize: 22,
                  fontWeight: 700,
                  padding: 1,
                }}
              >
                Place an order
              </Typography>
              <Box
                sx={{
                  border: "2px solid #DDDEE1",
                  p: 4,
                  borderRadius: "17px",
                  display: "flow",
                  margin: "0px auto",
                  width: "99%",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={2}>
                    <Card sx={{ boxShadow: "none", padding: 1 }}>
                      <Box sx={{ minWidth: 135, minHeight: 115 }}>
                        <img
                          width={"100%"}
                          height={"100%"}
                          src={
                            axonsMicroType === "S"
                              ? axonsmicro
                              : axonsmicrotwo
                          }
                          alt=""
                        />
                      </Box>
                    </Card>
                  </Grid>

                  <Grid item xs={4} sx={{ borderRight: "1px solid #D9D9D9" }}>
                    <Card sx={{ boxShadow: "none" }}>
                      <Box>
                        <Typography
                          sx={{
                            fontSize: 18,
                            fontWeight: 500,
                            fontFamily: "DM Sans",
                            color: "#000",
                            mb: 0.5, // Added margin-bottom for spacing
                          }}
                        >
                          Axons Micro
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: 14,
                            fontWeight: 500,
                            fontFamily: "DM Sans",
                            color: "#F67777",
                            mb: 0.5,
                          }}
                        >
                          Price Per Unit: ${axonsMicroPerUnitPrice}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: 14,
                            fontWeight: 400,
                            fontFamily: "DM Sans",
                            width: 288,
                            height: 26,
                            lineHeight: "15.86px",
                            color: "#535A6A",
                            mb: 3,
                          }}
                        >
                          {descriptionMicro}
                        </Typography>
                        <Box sx={{ display: "flex" }}>
                          <FormControl sx={{ mt: 5.5 }} size="small">
                            <Typography
                              sx={{
                                fontSize: 14,
                                fontWeight: 400,
                                fontFamily: "DM Sans",
                                width: 288,
                                height: 26,
                                lineHeight: "15.86px",
                                color: "#535A6A",
                              }}
                            >
                              Choose Quantity
                            </Typography>
                            <Select
                              sx={{
                                borderRadius: "12px",
                                width: 148,
                                height: 33,
                              }}
                              onChange={handleChangeMicro}
                              inputProps={{ "aria-label": "Without label" }}
                              value={quantityMicro}
                            >
                              {menuItemValues.map((value) => (
                                <MenuItem
                                  key={value}
                                  value={value}
                                  sx={menuItemStyles}
                                >
                                  {value}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl sx={{ mt: 5.5 }} size="small">
                            <Typography
                              sx={{
                                fontSize: 14,
                                fontWeight: 400,
                                fontFamily: "DM Sans",
                                width: 288,
                                height: 26,
                                lineHeight: "15.86px",
                                color: "#535A6A",
                              }}
                            >
                              Choose Device
                            </Typography>
                            <Select
                              sx={{
                                borderRadius: "12px",
                                width: 148,
                                height: 33,
                              }}
                              onChange={handleChangeDeviceTypeMicro}
                              inputProps={{ "aria-label": "Without label" }}
                              value={axonsMicroType}
                            >
                              {selectedDeviceType?.map((value) => (
                                <MenuItem
                                  key={value.value}
                                  value={value?.value}
                                  sx={menuItemStyles}
                                >
                                  {value?.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                        <Typography
                          sx={{
                            pt: 3, // Added padding-top for spacing
                            fontSize: 15,
                            fontWeight: 500,
                            fontFamily: "DM Sans",
                            color: "#535A6A",
                          }}
                        >
                          Total:{" "}
                          <span style={estimatedCostStyle}>
                            {formatCurrency(estimatedCost)}
                          </span>{" "}
                        </Typography>
                      </Box>
                    </Card>
                  </Grid>

                  <Grid item xs={2}>
                    <Card sx={{ boxShadow: "none", padding: 1 }}>
                      <Box sx={{ minWidth: 135, minHeight: 115 }}>
                        <img
                          width={"100%"}
                          height={"100%"}
                          src={
                            axonsMicroProType === "S"
                              ? axonsmicropro
                              : axonsmicroprotwo
                          }
                          alt=""
                        />
                      </Box>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card sx={{ boxShadow: "none" }}>
                      <Box>
                        <Typography
                          sx={{
                            fontSize: 18,
                            fontWeight: 500,
                            fontFamily: "DM Sans",
                            color: "#000",
                            mb: 0.5,
                          }}
                        >
                          Axons Micro Pro
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: 14,
                            fontWeight: 500,
                            fontFamily: "DM Sans",
                            color: "#F67777",
                            mb: 0.5,
                          }}
                        >
                          Price Per Unit: ${axonsMicroProPerUnitPrice}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: 14,
                            fontWeight: 400,
                            fontFamily: "DM Sans",
                            width: 288,
                            height: 26,
                            lineHeight: "15.86px",
                            color: "#535A6A",
                            mb: 3,
                          }}
                        >
                          {descriptionPro}
                        </Typography>
                        <Box sx={{ display: "flex" }}>
                          <FormControl sx={{ mt: 5.5 }} size="small">
                            <Typography
                              sx={{
                                fontSize: 14,
                                fontWeight: 400,
                                fontFamily: "DM Sans",
                                width: 288,
                                height: 26,
                                lineHeight: "15.86px",
                                color: "#535A6A",
                              }}
                            >
                              Choose Quantity
                            </Typography>
                            <Select
                              sx={{
                                borderRadius: "12px",
                                width: 148,
                                height: 33,
                              }}
                              onChange={handleChangeMicroPro}
                              inputProps={{ "aria-label": "Without label" }}
                              value={quantityPro}
                            >
                              {menuItemValues.map((value) => (
                                <MenuItem
                                  key={value}
                                  value={value}
                                  sx={menuItemStyles}
                                >
                                  {value}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl sx={{ mt: 5.5 }} size="small">
                            <Typography
                              sx={{
                                fontSize: 14,
                                fontWeight: 400,
                                fontFamily: "DM Sans",
                                width: 288,
                                height: 26,
                                lineHeight: "15.86px",
                                color: "#535A6A",
                              }}
                            >
                              Choose Device
                            </Typography>
                            <Select
                              sx={{
                                borderRadius: "12px",
                                width: 148,
                                height: 33,
                              }}
                              onChange={handleChangeDeviceTypeMicroPro}
                              inputProps={{ "aria-label": "Without label" }}
                              value={axonsMicroProType}
                            >
                              {selectedDeviceType.map((value) => (
                                <MenuItem
                                  key={value?.value}
                                  value={value?.value}
                                  sx={menuItemStyles}
                                >
                                  {value?.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                        <Typography
                          sx={{
                            pt: 2,
                            fontSize: 15,
                            fontWeight: 500,
                            fontFamily: "DM Sans",
                            color: "#535A6A",
                          }}
                        >
                          Total:{" "}
                          <span style={estimatedCostStyle}>
                            {formatCurrency(estimatedCostPro)}
                          </span>{" "}
                        </Typography>
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
                <Box sx={{ textAlign: "center", marginTop: 5 }}>
                  <Button
                    sx={{
                      fontSize: 14,
                      fontWeight: 500,
                      borderRadius: "12px",
                      width: 342,
                      height: 45,
                      background:
                        "linear-gradient(to right, rgba(83, 90, 106, 1) 40%, rgba(40, 50, 70, 1) 100%)",
                      color: "#f0f0f0",
                      border: "1px solid #DDDEE1",
                      textTransform: "none",
                      "&:disabled": {
                        background: "#F8F8F8",
                        color: "#747373",
                      },
                      "&:not(.Mui-disabled):hover": {
                        boxShadow: "none",
                        transform: "scale(1.05)",
                      },
                    }}
                    onClick={showPayment ? handlePayment : null}
                    disabled={
                      !paymentBtn ||
                      currentUserType === "AXONS" ||
                      currentUserType === "VENDOR"
                    }
                  >
                    Proceed to Checkout
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              bgcolor: "#fff",
              borderRadius: "0.625rem",
              mt: 2,
              mb: 2,
              p: 2,
              justifyContent: "start",
            }}
          >
            <Typography
              sx={{
                color: "#283246",
                fontSize: 22,
                fontWeight: 700,
                // padding: 1,
              }}
            >
              See What's New and Start Exploring
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                gap: 2,
              }}
            >
              <Grid item xs={12} md={4}>
                <FeatureCard
                  iconSrc={mobility}
                  title="Mobility On-Demand Refresh"
                  description="The latest update in mobile flexibility and fleet management, featuring advanced tracking and analytics."
                  descriptionone="The mobility market is changing fundamentally and at a rapid pace. Every day new offers, services, and technologies are appearing that are expected to revolutionise mobility. Only recently, e-scooters have conquered the market. More and more new mobility platforms offer multimodal information and increasingly also booking options"
                  descriptiontwo="Mobility-on-Demand is not a new idea. However, the concept is benefiting from digitisation and the increasing spread of smartphones, because customers and providers can contact each other in the shortest possible time and the offer can be scheduled using digital algorithms instead of analog mental calculation."
                  descriptivetitle="Mobility-on-Demand – Less timetable, more flexibility"
                  image={mobilityondemand}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FeatureCard
                  iconSrc={enhancedSecurity}
                  title="Enhanced Security Suite"
                  description="Strengthen your digital infrastructure with cutting-edge security solutions. Our Enhanced Security Suite offers advanced encryption, real-time threat monitoring, and proactive protection to safeguard your network."
                  descriptionone="In today’s rapidly evolving digital landscape, securing sensitive information is crucial. Our Enhanced Security Suite ensures comprehensive protection with multi-layered defenses and automated threat responses, keeping your operations safe from emerging cyber risks."
                  descriptiontwo="By integrating intelligent security algorithms, our suite minimizes vulnerabilities and enhances your overall resilience against cyber attacks. The future of security lies in advanced analytics, AI-driven monitoring, and real-time response strategies."
                  descriptivetitle="Enhanced Security – Robust Protection, Real-Time Threat Response"
                  image={enhancedsecuritysuite}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FeatureCard
                  iconSrc={dataInsights}
                  title="Data Insights"
                  description="Empower your decision-making with dynamic data analytics. Our Data Insights Dashboard provides real-time, customizable visualizations that uncover trends, patterns, and opportunities hidden in your data."
                  descriptionone="In a world driven by data, uncovering actionable insights is key to staying competitive. Our Data Insights Dashboard transforms complex data into intuitive visuals, enabling teams to quickly identify trends and optimize their strategies."
                  descriptiontwo="Data is the foundation of modern business intelligence. With our tools, you can easily customize dashboards, access real-time metrics, and integrate diverse data sources to make informed, data-driven decisions."
                  descriptivetitle="Data Insights – Visualize, Analyze, Optimize"
                  image={datainsightsimage}
                />
              </Grid>
            </Box>
          </Box>
        </>
      )}
      {currentUserRole === "Super Admin" && (
        <>
          <Box
            sx={{
              bgcolor: "#fff",
              borderRadius: "0.625rem",
              mt: 2,
              mb: 2,
              p: 2,
              justifyContent: "start",
            }}
          >
            <Typography
              sx={{
                color: "#283246",
                fontSize: 22,
                fontWeight: 700,
                padding: 1,
              }}
            >
              Generate Dummy Data for Organization
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)", // Ensures 3 columns of equal width
                gap: "1rem",
                border: "1px solid #DDDEE1",
                borderRadius: "10px",
                padding: "18px",
              }}
            >
              {/* Row 1 */}
              <Box>
                <Typography
                  sx={{
                    fontFamily: theme.typography.fontFamily,
                    fontSize: "0.75rem",
                    padding: "0.313rem",
                    color: theme.palette.secondary.main,
                  }}
                >
                  Min Lat/Lng
                </Typography>
                <TextField
                  name="minlatlng"
                  value={
                    minLatLng.lat && minLatLng.lng
                      ? `${minLatLng.lat}, ${minLatLng.lng}`
                      : ""
                  }
                  onClick={() => {
                    setCurrentField("min");
                    setOpenMapDialog(true);
                  }}
                  error={!!minErrorMessage}
                  helperText={minErrorMessage}
                  placeholder="min lat/lng"
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "0.75rem",
                      "& input": {
                        padding: "0.625rem",
                        height: "auto",
                        color: theme.palette.primary.main,
                        fontFamily: theme.typography.fontFamily,
                      },
                    },
                    "& .MuiOutlinedInput-root.Mui-disabled": {
                      backgroundColor: "#F8F8F8",
                      borderColor: "#DDDEE1",
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: theme.palette.primary.borderColor,
                      },
                  }}
                />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontFamily: theme.typography.fontFamily,
                    fontSize: "0.75rem",
                    padding: "0.313rem",
                    color: theme.palette.secondary.main,
                  }}
                >
                  Max Lat/Lng
                </Typography>
                <TextField
                  value={
                    maxLatLng.lat && maxLatLng.lng
                      ? `${maxLatLng.lat}, ${maxLatLng.lng}`
                      : ""
                  }
                  onClick={() => {
                    setCurrentField("max");
                    setOpenMapDialog(true);
                  }}
                  placeholder="max lat/lng"
                  error={!!maxErrorMessage} // Set error state based on maxErrorMessage
                  helperText={maxErrorMessage} // Display the max error message
                  sx={{
                    width: "100%",
                    borderRadius: "0.75rem",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "0.75rem",
                      "& input": {
                        padding: "0.625rem",
                        height: "auto",
                        color: theme.palette.primary.main,
                        fontFamily: theme.typography.fontFamily,
                      },
                    },
                    "& .MuiOutlinedInput-root.Mui-disabled": {
                      backgroundColor: "#F8F8F8",
                      borderColor: "#DDDEE1",
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: theme.palette.primary.borderColor,
                      },
                  }}
                />
              </Box>

              <Button
                variant="contained"
                color="primary"
                sx={{
                  marginLeft: "0.2rem",
                  textTransform: "none",
                  backgroundColor: "primary.main",
                  color: "white",
                  borderRadius: "8px",
                  padding: "0.5rem 1.5rem",
                  maxWidth: "150px",
                  height: 40,
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                  background:
                    "linear-gradient(to right, rgba(83, 90, 106, 1) 40%, rgba(40, 50, 70, 1) 100%)",
                  "&:hover": {
                    background:
                      "linear-gradient(to right, rgba(83, 90, 106, 1) 40%, rgba(40, 50, 70, 1) 100%)",
                    boxShadow: "none",
                    transform: "scale(1.05)",
                  },
                }}
                disabled={loading}
                onClick={handleSeedData}
              >
                {loading ? (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                ) : (
                  "Generate Data"
                )}
              </Button>
            </Box>{" "}
            {/* Map Dialog */}
            <Dialog
              open={openMapDialog}
              onClose={() => setOpenMapDialog(false)}
              fullWidth
              maxWidth="lg"
            >
              {/* <Box>
                <Map
                  mapStyle="mapbox://styles/yogendrakokate/cm3r6n0xn004n01s68le2b54u"
                  mapboxAccessToken={MAPBOX_TOKEN}
                  {...viewport}
                  onMove={(evt) => setViewport(evt.viewState)}
                  onClick={handleMapClick}
                  style={{
                    height: "80vh",
                    width: "100%",
                    overflow: "hidden",
                    borderRadius: "1rem",
                  }}
                  ref={mapRef}
                >
                  {minLatLng.lat && minLatLng.lng && (
                    <Marker longitude={minLatLng.lng} latitude={minLatLng.lat}>
                      <Box>
                        <img src={minlatlng} alt="Min Marker" />
                      </Box>
                    </Marker>
                  )}
                  {maxLatLng.lat && maxLatLng.lng && (
                    <Marker longitude={maxLatLng.lng} latitude={maxLatLng.lat}>
                      <Box>
                        <img src={maxlatlng} alt="Max Marker" />
                      </Box>
                    </Marker>
                  )}

                  <FullscreenControl position="top-right" />
                  <NavigationControl position="bottom-right" />
                </Map>
              </Box> */}
              {renderMap()}
            </Dialog>
          </Box>
        </>
      )}
    </Paper>
  );
}
