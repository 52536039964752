import React, { Suspense, lazy, useLayoutEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import LoadingUser from "./components/LoadingUser";
import PaymentSuccess from "./components/PaymentSuccess";
import PageNotFound from "./components/PageNotFound";
import PaymentMethod from "./pages/Payments";
import { useSelector } from "react-redux";
import PaymentSubscriber from "./pages/PaymentSubscriber";
import PaymentSuccessSubscriber from "./pages/PaymentSubscriber/PaymentSuccessSubscriber";
import { MessageWidget } from "./components/CampaignWidget";
// Lazy load the components
const Dashboard = lazy(() => import("./pages/Dashboard/index"));
const Login = lazy(() => import("./pages/Login/LoginPage"));

const AppRoutes = () => {
  const isAuthenticated = useSelector((state) => state?.auth?.isAuthenticated);
  const urlParams = new URLSearchParams(window.location.search);

  useLayoutEffect(()=>{
    if (urlParams.get('from_dashboard1') === 'true') {
      // Clear session and local storage
      sessionStorage.clear();
      localStorage.clear();
  
      window.location.href = '/';  // Or show the login form directly
  }
  },[urlParams])

  return (
    <Suspense fallback={<LoadingUser />}>
      <Routes>
        <Route
          path="/"
          element={
            !isAuthenticated ? <Login /> : <Navigate to="/dashboard" replace />
          }
        />
        <Route
          path="/dashboard/*"
          element={<PrivateRoute component={Dashboard} />}
        />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="payment/:id" element={<PaymentMethod />} />
        <Route path="payment" element={<PaymentSubscriber/>}/>
        <Route path="/payment-confirmation" element={<PaymentSuccessSubscriber/>}/>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      {/* <MessageWidget/> */}
    </Suspense>
  );
};

export default AppRoutes;
