import React, { useEffect, useState } from "react";
import { Fab, Tooltip, Box } from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";
import { useNavigate } from "react-router-dom";
import { keyframes } from "@emotion/react";
import Pusher from "pusher-js";
import { useGetData } from "../hooks/useApi";
import { useSnackbar } from "../hooks/Snackbar";

export const MessageWidget = () => {
  const navigate = useNavigate();
  const getData = useGetData()
  const [presenceChannel, setPresenceChannel] = useState();
  const [serviceStation, setServiceStation] = useState();
  const [tooltipMsg,setTooltipMsg] = useState("")
  const showSnackbar  = useSnackbar()

  const getStationList = async () => {
    try {
      const { data } = await getData.mutateAsync({
        endpoint: "/manage/service-stations",
        data: { page: "ALL" },
      });
      if (data) {
        setServiceStation(data);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        showSnackbar(
          "Your session has ended because your account was logged in on another device.",
          "error"
        );
        setServiceStation([]);
        return;
      }
      setServiceStation([]);
    }
  };

  useEffect(()=>{
    getStationList()
  },[])

  useEffect(() => {
    // Initialize Pusher
    const pusher = new Pusher("54f18cda639e51628a0f", {
      cluster: "ap2",
    });
    const channel = pusher.subscribe("axons");
    setPresenceChannel(channel);
    pusher.connection.bind("state_change", function (states) {
      console.log("Pusher connection state:", states.current);
    });
    return () => {
      if (presenceChannel) {
        presenceChannel.unbind_all();
        presenceChannel.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    if (presenceChannel && serviceStation) {
      serviceStation?.forEach((station) => {        
        presenceChannel.bind(`user_${station.id}`, (res) => {
          console.log(res,'---res')
          let messageData;
          try {
            messageData = typeof res === "string" ? JSON.parse(res) : res;
            console.log(messageData, `Message received for station ID: ${station.id}`);
  
            // Example action: Show a notification or update UI
            if (messageData.unread_count>0) {
              setTooltipMsg(`New message for station: ${station.organization_name}`);
            }else{
              setTooltipMsg(``);
            }
          } catch (error) {
            console.error(`Error parsing message data for station ID: ${station.id}`, error);
          }
        });
      });
  
      return () => {
        serviceStation.forEach((station) => {
          presenceChannel.unbind(station.id);
        });
      };
    }
  }, [presenceChannel, serviceStation]);
  

  const handleThreadOpen = () => {
    navigate("/dashboard/service-requests"); // Navigate to the service requests page
  };

  // Pulsating animation for the badge
  const pulse = keyframes`
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.2);
      }
      100% {
        transform: scale(1);
      }
    `;

  return (
    <div>
      <Tooltip
        title={tooltipMsg ? tooltipMsg : "No new messages"}
        placement="top"
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: "#3F4A5C", // A dark blue-grey shade that complements the sidebar
              fontWeight: 500,
              fontSize: "14px",
              color: "#FFFFFF", // White text for good contrast
              padding: "10px 15px",
              borderRadius: "8px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)", // Slightly darker shadow
            },
          },
          arrow: {
            sx: {
              color: "#3F4A5C", // Matching the tooltip background
            },
          },
        }}
      >
        <Fab
          color="primary"
          aria-label="new message"
          onClick={handleThreadOpen}
          style={{ position: "fixed", bottom: 16, right: 16 }}
        >
          <Box
            position="relative"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <MessageIcon fontSize="large" />
            {/* Floating Badge */}
            {tooltipMsg && (
              <Box
                position="absolute"
                top={-6}
                right={-6}
                bgcolor="error.main"
                width={12}
                height={12}
                borderRadius="50%"
                boxShadow="0 0 6px rgba(0, 0, 0, 0.3)"
                animation={`${pulse} 1.5s infinite`}
                zIndex={1}
              />
            )}
          </Box>
        </Fab>
      </Tooltip>
    </div>
  );
};
