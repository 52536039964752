import React from "react";
import { Box, Card, Typography } from "@mui/material";
import Successanim from "../../assets/image/success.svg";

const PaymentSuccessSubscriber = () => {
  return (
    <Card
      sx={{ justifyContent: "center", alignItems: "center", boxShadow: "none" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "85vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 1,
            maxWidth: "600px",
            width: "100%",
            textAlign: "center",
          }}
        >
          <img
            src={Successanim}
            alt="Payment Success"
            style={{ width: "300px", marginBottom: "1rem" }}
          />

          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontSize: "1rem",
              color: "#283246",
              marginBottom: "1.5rem",
              fontFamily: "DM Sans, Arial, sans-serif",
            }}
          >
            Payment Successful! Thank you for your payment. We appreciate your
            trust in us and look forward to providing you with excellent
            service.
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default PaymentSuccessSubscriber;
