import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

// Extend dayjs with plugins
dayjs.extend(utc);
dayjs.extend(timezone);

export const formatDate = (dateString, timeZone = "UTC") => {
  const effectiveTimeZone = timeZone || "UTC";

  // Check if dateString is valid
  if (!dateString) {
    return "-";
  }

  try {
    // Parse the date string as a UTC date
    const date = dayjs.utc(dateString, "YYYY-MM-DD");
    if (!date.isValid()) {
      return "-"; // Handle invalid date
    }

    // Format date as 'Month Day, Year' in the specified timezone
    return date.tz(effectiveTimeZone).format("MMMM D, YYYY");
  } catch (error) {
    // Catch any unexpected errors and return "-"
    console.error("Error formatting date:", error);
    return "-";
  }
};

export const formatTime = (dateString, timeZone = "UTC") => {
  const effectiveTimeZone = timeZone || "UTC";
  // Check if dateString is valid
  if (!dateString) {
    return "-";
  }

  try {
    // Parse the date string as a UTC date
    const date = dayjs.utc(dateString, "YYYY-MM-DD HH:mm:ss");
    // if (!date.isValid()) {
    //   return "-"; // Handle invalid date
    // }
    // Format the time in the specified time zone
    return date.tz(effectiveTimeZone).format("HH:mm:ss"); // Use 24-hour format; customize as needed
  } catch (error) {
    // Catch any unexpected errors and return "-"
    console.error("Error formatting time:", error);
    return "-";
  }
};

export const convertToTimeInTimeZone = (dateString, timeZone = "UTC") => {
  const effectiveTimeZone = timeZone || "UTC";

  // Check if dateString is valid
  if (!dateString) {
    console.log('data-string-rejected')
    return null;
  }

  console.log("hey",effectiveTimeZone)
  try {
    let date;

    // If dateString contains only time (HH:mm), assume it's UTC and apply the time zone conversion
    const isTimeOnly = /^(\d{2}):(\d{2})(:(\d{2}))?$/.test(dateString);
    if (isTimeOnly) {
      // Assuming today's date with the time provided, and parsing it as UTC
      date = dayjs.utc(`${dayjs().format("YYYY-MM-DD")} ${dateString}`, "YYYY-MM-DD HH:mm");
    } else {
      // Parse the date string as a UTC date with the full date-time format
      date = dayjs.utc(dateString, "YYYY-MM-DD HH:mm:ss");
    }

    if (!date.isValid()) {
      return "-"; // Handle invalid date
    }

    // Format the time in the specified time zone (HH:mm for hours and minutes)
    return date.tz(effectiveTimeZone).format("HH:mm:ss"); // Format as HH:mm
  } catch (error) {
    // Catch any unexpected errors and return "-"
    console.error("Error formatting time:", error);
    return "-";
  }
};

export const convertToUTC = (timeString, timeZone= "UTC") => {
  // Validate input
  if (!timeString || !timeZone) {
    console.error("Invalid input: timeString and timeZone are required.");
    return null;
  }

  try {
    let localTime;

    // Check if the input is a time-only string (e.g., "01:00:00")
    const isTimeOnly = /^(\d{2}):(\d{2})(:(\d{2}))?$/.test(timeString);
    if (isTimeOnly) {
      // Assume today's date combined with the input time in the provided timezone
      localTime = dayjs.tz(
        `${dayjs().format("YYYY-MM-DD")} ${timeString}`,
        timeZone
      );
    } else {
      // Treat the input as a full date-time string and parse it in the provided timezone
      localTime = dayjs.tz(timeString, timeZone);
    }

    if (!localTime.isValid()) {
      console.error("Invalid time string format.");
      return null;
    }

    // Convert the local time to UTC and return only the time part
    return localTime.utc().format("HH:mm:ss");
  } catch (error) {
    console.error("Error converting time to UTC:", error);
    return null;
  }
};

export const formatDateTime = (dateString, timeZone = "UTC") => {
  const effectiveTimeZone = timeZone || "UTC";

  // Check if dateString is valid
  if (!dateString) {
    return "-";
  }

  try {
    // Parse the date string as a UTC date
    const date = dayjs.utc(dateString, "YYYY-MM-DD HH:mm:ss");
    if (!date.isValid()) {
      return "-"; // Handle invalid date
    }

    // Format date and time as 'YYYY-MM-DD HH:mm:ss' in the specified timezone
    return date.tz(effectiveTimeZone).format("YYYY-MM-DD HH:mm:ss");
  } catch (error) {
    // Catch any unexpected errors and return "-"
    console.error("Error formatting date and time:", error);
    return "-";
  }
};

export const convertMinutesToTimeFormat = (minutes = "") => {
  if (minutes === "") {
    return "";
  }

  const totalSeconds = Math.floor(minutes * 60); // Ensure totalSeconds is an integer
  const days = Math.floor(totalSeconds / (24 * 3600)); // Calculate the number of days
  const remainingSeconds = totalSeconds % (24 * 3600); // Remaining seconds after days
  const hours = Math.floor(remainingSeconds / 3600); // Calculate hours
  const remainingMinutes = Math.floor((remainingSeconds % 3600) / 60); // Remaining minutes
  const seconds = remainingSeconds % 60; // Remaining seconds

  // Format the result as "X days HH:MM:SS" or "HH:MM:SS"
  if (days > 0) {
    return `${days} days ${hours.toString().padStart(2, "0")}:${remainingMinutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  } else {
    return `${hours.toString().padStart(2, "0")}:${remainingMinutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  }
};

export const formatCurrency = (amount) => {
  if (isNaN(amount)) {
    return "-";
  }
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);
};

export const formatErrorMessage = (error) => {
  if (error?.errors && typeof error.errors === "object") {
    const { errors } = error;
    const errorList = Object.values(errors)
      .flat() // Flatten arrays (e.g., arrays of error messages)
      .filter((msg) => msg); // Remove empty or falsy values

    return errorList.length ? errorList : "";
  }

  // Fallback for raw API error structure
  if (error?.response?.data) {
    const { message, errors } = error.response.data;

    if (errors && typeof errors === "object") {
      const errorList = Object.values(errors)
        .flat()
        .filter((msg) => msg);

      return errorList.length ? errorList : "";
    }

    return message ? [message] : "";
  }

  // If no error is present, return an empty string
  return "";
};
