// src/hooks/useApi.js
import { useMutation, useQueryClient } from "react-query";

import api, { FileUploadapi } from "../api";

// Hook for GET request
export const useGetData = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ endpoint, data }) => {
      // Reconstruct the query params conditionally
      const queryParams = {};
      if (data.page) queryParams.page = data.page;
      if (data.search)
        queryParams.search =
          typeof data.search === "string" ? data.search : data.search.search;
      if (data.status) queryParams.status = data.status;
      if (data.app_issue_id) queryParams.app_issue_id = data.app_issue_id;
      if (data.date) queryParams.date = data.date;
      if (data.per_page_data) queryParams.per_page_data = data.per_page_data;
      if (data.from) queryParams.from = data.from;
      if (data.priority) queryParams.priority = data.priority;
      if (data.issue_type_id) queryParams.issue_type_id = data.issue_type_id;
      if (data.org_id) queryParams.org_id = data.org_id;
      if (data.thread_type) queryParams.thread_type = data.thread_type;
      const queryString = new URLSearchParams(queryParams).toString();
      const response = await api.get(
        `${endpoint}?${queryString}`
        // `${endpoint}${queryString?.length && "?"}${queryString}`
      );

      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getData");
      },
    }
  );
};

export const useFirmDetailsData = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ endpoint }) => {
      const response = await api.get(endpoint);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("firmdetails");
      },
    }
  );
};
export const useFirmUpdateGetData = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ endpoint, data }) => {
      const searchParam =
        typeof data.search === "string" ? data.search : data.search?.search;
      const queryString = new URLSearchParams({
        page: data.page || "",
        ...(data.search && { search: searchParam || "" }),
        update_firmware_id: data.update_firmware_id,
        ...(data.status && { status: data.status || "" }),
        ...(data.hw_versions && { hw_versions: data.hw_versions }),
        ...(data.fw_versions && { fw_versions: data.fw_versions }),
      });

      const response = await api.get(`${endpoint}?${queryString}`);
      return response?.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getData");
      },
    }
  );
};

// Hook for POST request
export const usePostData = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ endpoint, data }) => {
      const response = await api.post(endpoint, data);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getData");
      },
    }
  );
};

export const usePatchData = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ endpoint, data }) => {
      const response = await api.patch(endpoint, data);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getData");
      },
    }
  );
};

export const useDeleteData = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ endpoint }) => {
      const response = await api.delete(endpoint);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getData");
      },
    }
  );
};

export const useUploadFile = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ endpoint, data }) => {
      const response = await FileUploadapi.post(endpoint, data);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getData");
      },
    }
  );
};

export const useUploadFilePatch = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ endpoint, data }) => {
      const response = await FileUploadapi.patch(endpoint, data);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getData");
      },
    }
  );
};
