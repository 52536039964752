import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  errors: {},
  activeStationStep: 1,
  addBasicDetails: {
    business_name: "",
    name: "",
    email: "",
    contact_number: "",
    street_address: "",
    city: "",
    state: "",
    country: "",
    zip_code: "",
    capacity:"",
 location:{
  latitude:0,
  longitude:0
 }
  },
  partsList:[],
  servicePerson:[],
  serviceStations:[],
  workingHors:[],
  editBasicDetails: {
  },
  editWorkingHours:[],
};

const savedStep = localStorage.getItem("activeStationStep");
if (savedStep !== null) {
  initialState.activeStationStep = parseInt(savedStep, 10);
}

const ServiveRequestSlice = createSlice({
  name: "serviceRequest",
  initialState,
  _reducers: {
    setErrorList: (state, action) => {
      state.errors = action.payload;
    },
    setServiceStation: (state, action) => {
      state.serviceStations = action.payload;
    },
    setactiveStationStep: (state, action) => {
      state.activeStationStep = action.payload;
      localStorage.setItem("activeStationStep", action.payload);
    },
    setPartsUpdate:(state,action)=>{
      state.partsList = action.payload

    },
    setServiceUpdate:(state,action)=>{
      state.servicePerson = action.payload
    },
    resetStationStep: (state) => {
      state.activeStationStep = 1;
      localStorage.removeItem("activeStationStep");
    },
    setBasicDetails: (state, action) => {
      state.addBasicDetails = {
        ...state.addBasicDetails,
        ...action.payload,
      };
    },
    setEditBasicDetails: (state, action) => {
      state.editBasicDetails = {
        ...state.editBasicDetails,
        ...action.payload,
      };
    },
    setUpdatedWorkingHours: (state, action) => {
      state.workingHors = action.payload;
    },
  },
  get reducers() {
    return this._reducers;
  },
  set reducers(value) {
    this._reducers = value;
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", () => initialState);
  },
});

export const {
  setactiveStationStep,
  setServiceStation,
  resetStationStep,
  setBasicDetails,
  setEditBasicDetails,
  setErrorList,
  setUpdatedWorkingHours,
  setPartsUpdate,
  setServiceUpdate
} = ServiveRequestSlice.actions;
export default ServiveRequestSlice.reducer;
