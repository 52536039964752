import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  assets: [],
  errorsList: {},
  activeAccountStep: 1,
  addBasicDetails: {
    name: "",
    email: "",
    contact_number: "",
    country_code: "",
  },
  assetPetName: [],
  editBasicDetails: {},
  assetSelection: {
    selectedQrCode: [],
  },
  unAssigneAxons: [],
  selectViewId: "",
  assignUnassignAsset: [],
  perPageOptions: 10,
};

const savedStep = localStorage.getItem("activeSubscriberAccountStep");
if (savedStep !== null) {
  initialState.activeAccountStep = parseInt(savedStep, 10);
}

const subscriberSlice = createSlice({
  name: "subscriber",
  initialState,
  reducers: {
    updateSubscribers: (state, action) => {
      state.data = action.payload;
    },
    setSubscriberErrorList: (state, action) => {
      state.errorsList = action.payload;
    },
    updateAssets: (state, action) => {
      state.assets = action.payload;
    },
    setSubscriberActiveAccountStep: (state, action) => {
      state.activeAccountStep = action.payload;
      localStorage.setItem("activeSubscriberAccountStep", action.payload);
    },
    resetSubscriberAccountStep: (state, action) => {
      state.activeAccountStep = 1;
      localStorage.removeItem("activeSubscriberAccountStep");
    },
    setSubscriberBasicDetails: (state, action) => {
      state.addBasicDetails = {
        ...state.addBasicDetails,
        ...action.payload,
      };
    },
    setSubscriberAssetSelection: (state, action) => {
      state.assetSelection = {
        ...state.assetSelection,
        ...action.payload,
      };
    },
    setEditSubscriberBasicDetails: (state, action) => {
      state.editBasicDetails = {
        ...state.editBasicDetails,
        ...action.payload,
      };
    },
    setSubscirberAssignUnassignAsset: (state, action) => {
      state.assignUnassignAsset = action.payload;
    },
    setSubscriberSelectedViewId: (state, action) => {
      state.selectViewId = action.payload;
    },
    updateSubscriberUnAssigneAxons: (state, action) => {
      state.unAssigneAxons = action.payload;
    },
    setPerPageOption: (state, action) => {
      state.perPageOptions = action.payload;
    },
    setAssetPetName: (state, action) => {
      state.assetPetName = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", () => initialState);
  },
});

export const {
  updateSubscribers,
  setSubscriberErrorList,
  updateAssets,
  setSubscriberBasicDetails,
  setSubscriberAssetSelection,
  updateSubscriberUnAssigneAxons,
  setSubscriberSelectedViewId,
  setEditSubscriberBasicDetails,
  setSubscirberAssignUnassignAsset,
  setSubscriberActiveAccountStep,
  resetSubscriberAccountStep,
  setPerPageOption,
  setAssetPetName,
} = subscriberSlice.actions;
export default subscriberSlice.reducer;
