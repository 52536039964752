import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Defaults to localStorage for web

// Import slice reducers
import authReducer from "./slices/authSlice";
import analyticsReducer from "./slices/analyticsSlice";
import mapDataReducer from "./slices/mapDataSlice";
import usersReducer from "./slices/usersSlice";
import devicesReducer from "./slices/devicesSlice";
import settingsReducer from "./slices/settingsSlice";
import subscriberReducer from "./slices/subscriberSlice";
import myOrderReducer from "./slices/myOrderSlice";
import assetReducer from "./slices/assetSlice";
import paymentReducer from "./slices/paymentSlice";
import planDetailReducer from "./slices/planDetailsSlice";
import firmwareReducer from "./slices/firmwareSlice";
import stepperReducer from "./slices/stepperSlice";
import historyReducer from "./slices/updateHistorySlices";
import AuthorityReducer from "./slices/authority";
import AssetTypeReducer from "./slices/assetTypeSlice";
import PermissionReducer from "./slices/permissionsSlice";
import SidebarOptionsReducer from "./slices/sidebarSlice";
import SubAccountReducer from "./slices/subAccountSlice";
import workOrderReducer from "./slices/workOrderSlice";
import serviceReducer from "./slices/serviceRequestSlice.js";

// Combine all reducers into a single root reducer
const rootReducer = combineReducers({
  auth: authReducer,
  analytics: analyticsReducer,
  mapData: mapDataReducer,
  users: usersReducer,
  devices: devicesReducer,
  settings: settingsReducer,
  subscribers: subscriberReducer,
  orders: myOrderReducer,
  assets: assetReducer,
  payment: paymentReducer,
  plan: planDetailReducer,
  firmware: firmwareReducer,
  stepper: stepperReducer,
  updateHistory: historyReducer,
  authority: AuthorityReducer,
  assetType: AssetTypeReducer,
  permission: PermissionReducer,
  sidebaroptions: SidebarOptionsReducer,
  subAccounts: SubAccountReducer,
  workOrder: workOrderReducer,
  serviceRequests:serviceReducer
});

// Middleware reducer for handling logout or state reset actions
const LogoutReducer = (state, action) => {
  if (action.type === "auth/logout") {
    state = undefined; // Reset the state on logout
  }
  return rootReducer(state, action);
};

// Redux Persist configuration
const persistConfig = {
  key: "root",
  storage,
  // Add whitelist or blacklist options if needed to persist only certain reducers
  // whitelist: ['auth', 'settings'],
  // blacklist: ['analytics'],
};

// Persisted reducer
const persistedReducer = persistReducer(persistConfig, LogoutReducer);

// Configure Redux store
const store = configureStore({
  reducer: persistedReducer,
  // Add middleware or devTools configuration if necessary
});

// Persistor instance for managing persistence
const persistor = persistStore(store);

/**
 * Utility function to clear persisted data after a specified duration
 * @param {number} timeout - Duration in milliseconds before clearing persisted data
 */
const clearPersistedDataAfterTimeout = (timeout = 43200000) => { // 12 hours = 43,200,000 ms
  setTimeout(() => {
    persistor.purge(); // Clears persisted data from storage
  }, timeout);
};

// Start the timeout to clear persisted data
clearPersistedDataAfterTimeout();

export { store, persistor };
